import $ from 'jquery';
// import '../../../node_modules/bootstrap/js/src/dropdown';
import '../../../node_modules/bootstrap/js/src/modal';
import '../../../node_modules/bootstrap/js/src/collapse';

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"

window.Rails = Rails
Rails.start()


import Swal from 'sweetalert2';


$(document).ready(function(){
	const courseSelector = $('.course-selector');
	const fromLangButton = courseSelector.find('[data-type="from-lang"]');
	const toLangButton = courseSelector.find('[data-type="to-lang"]');
	listenReviewItemsClick();

	let fromLang, toLang;
	if(fymol.courses){
		let courseIndex = 0;
		const selectedCourseSlug = courseSelector.data('selected');
		if(selectedCourseSlug){
			courseIndex = fymol.courses.findIndex(course => course.slug === selectedCourseSlug);
		}
		fromLang = $('#language-selection-modal .modal-body button[data-id="'+fymol.courses[courseIndex].language_id+'"]');
		toLang = $('#language-selection-modal .modal-body button[data-id="'+fymol.courses[courseIndex].to_language_id+'"]');

		$(window).on('pageshow', function(){
			fromLang = $('#language-selection-modal .modal-body button[data-id="'+fymol.courses[courseIndex].language_id+'"]');
			toLang = $('#language-selection-modal .modal-body button[data-id="'+fymol.courses[courseIndex].to_language_id+'"]');
		});
	}

	function SetCourse(fromLang, toLang, init){
		const course = fymol.courses.find(c => c.language_id == fromLang.data('id') && c.to_language_id == toLang.data('id'));

		fromLangButton.find('img').attr('src', fromLang.data('image'));
		fromLangButton.find('div > div').text(fromLang.data('lang'));

		toLangButton.find('img').attr('src', toLang.data('image'));
		toLangButton.find('div > div').text(toLang.data('lang'));

		const baseRoute = courseSelector.data('base-route');
		if(baseRoute){
			courseSelector.attr('action', `${baseRoute}/${course.slug}`);
		}
		else
		{
			courseSelector.attr('action', `/course/${course.slug}`);
		}
		if(!init && courseSelector.attr('onchange')){
			console.log("onchange")
			courseSelector.trigger('onchange');
		}

	}

	if(!fymol.courses){
		fymol.courses = [];
	}
	else{
		SetCourse(fromLang, toLang, true);
	}


	let targetLanguageSelection = null;
	let ids;
	$('#language-selection-modal').on('show.bs.modal', function(event){
		targetLanguageSelection = event.relatedTarget;
		$(this).find('.modal-body button').removeClass('d-none');

		$(this).find('#languageSelectionLabel').text(targetLanguageSelection.dataset.label);
		if(targetLanguageSelection.dataset.type === 'from-lang'){
			ids = fymol.courses.map(c => c.language_id);
		}
		else{
			ids = fymol.courses.map(c => c.to_language_id);
		}
		ids = ids.filter((id,index) => ids.indexOf(id) === index);

		$(this).find('.modal-body button').filter(function(){
			return ids.indexOf(parseInt(this.dataset.id)) === -1;
		}).addClass('d-none');


		$(this).find('.modal-body button').one('click', event2 => {
			if(targetLanguageSelection.dataset.type === 'from-lang'){
				fromLang = $(event2.target);
			}
			else{
				toLang = $(event2.target);
			}
			SetCourse(fromLang, toLang);
		})
	});

	$('#language-selection-modal').on('hide.bs.modal', function(event){
	})	

	$('.image-with-effect').each(function(){ 
		const img = $(this).find('img').clone();
		img.addClass('image-effect');
		img.prependTo(this);
	})

	if(fymol.alert){
		Swal.fire({
			text: fymol.alert,
			icon: 'error',
		})
	}

	if(fymol.notice){
		Swal.fire({
			text: fymol.notice,
			icon: 'info',
		})
	}

	$('.language-buttons-dropdown').on('mouseleave', function(e){
		this.scrollTop = 0;
	});

	$('.language-buttons-dropdown > a:first').on('click', function(e){
		e.preventDefault();
	})

	$('iframe').each(function(e){
		if(this.hasAttribute('width') && this.hasAttribute('height')){
			const width = parseFloat(this.getAttribute('width'));
			const height = parseFloat(this.getAttribute('height'));
			const ratio = width / height;
			this.style.aspectRatio = ratio;
		}
	})
})// document.ready

$(document).on('ajax:success', function(e){
	const response = e.originalEvent.detail[0];
	const icon = response.state ? 'success' : 'error';

	if(response.message){
		Swal.fire({
		  // title: response.state ? 'Success!' : 'Error!',
		  text: response.message,
		  icon: icon,
		  // confirmButtonText: 'Cool'
		}).then(() => {
			if(response.redirect){
				window.location.href = response.redirect;
			}
		})
	}
	else{
		if(response.redirect){
			window.location.href = response.redirect;
		}
	}
})

function listenReviewItemsClick() {
	document.querySelectorAll('.event-item > a > p').forEach(function (item) {
		item.addEventListener('click', function (event) {
			if(event.target.scrollHeight < event.target.offsetHeight){
				return;
			}
			if (!event.target.classList.contains('expanded')) {
				event.preventDefault();
			}
			event.target.classList.add('expanded');
		})
	});
}
